import { API_ENDPOINTS } from 'config';
import { getFormData } from 'utils/formData';
import { ApiService } from '../apiService';

class Notification extends ApiService {
  getNotifications(params) {
    return this.get(API_ENDPOINTS.notification.root, params);
  }

  getNotificationReceivers(id, params) {
    return this.get(`${API_ENDPOINTS.notification.receivers()}${id}/`, params);
  }

  getNotification(id) {
    return this.get(`${API_ENDPOINTS.notification.root}${id}/`);
  }

  addNotification({ attachment, residents, filters, ...other }) {
    const {type, ...restFilters} = filters;
    const formData = getFormData({...other, ...restFilters, ...(type && {property_type: type})});

    (residents || []).map((file) => formData.append('residents', file));
    (attachment || []).map((file) => formData.append('attachment', file));

    return this.post(API_ENDPOINTS.notification.root, formData);
  }
}

export default new Notification();
